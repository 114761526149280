import { OutputData } from "@editorjs/editorjs";
import { DateTime } from "luxon";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { BusinessCategory } from "src/entities/BusinessCategory";
import { CountryPath } from "src/entities/CountryPath";
import { GetArticlesResponse } from "src/entities/GetArticlesResponse";
import { SearchKeyword } from "src/entities/SearchKeyword";
import uploadImageByFile from "src/libs/image/post-image-by-image";
import {
  PostImageByFileResponseSchema,
  PostImageByFileResponseSchemaV2,
} from "src/libs/types/image";
import createBusinessCategories from "src/utils/createBusinessCategories";
import { createHtmlBody } from "src/utils/editorjs/createHtmlBody";
import isPngOrJpeg from "src/utils/isPngOrJpeg";
import { useAuth } from "./useAuth";
import { usePost } from "./usePost";

interface Props {
  input: {
    publisherId: string;
    title: string;
    outputBody: OutputData;
    country: CountryPath;
    acceptedFiles: File[];
    publishedAt: DateTime;
    businessCategories: BusinessCategory[];
    selectedCategories: string[];
    summary: string;
    companyInfo: string;
    mediaContact: string;
    useThumbnailAsFeaturedImage: boolean;
    searchKeywords: SearchKeyword[];
  };
  functions: {
    handleBackDropOpen: () => void;
    handleClose: () => void;
    handleScheduleClose: () => void;
    handleBackdropClose: () => void;
    handleSave: () => Promise<void>;
    handleComplete: (response: GetArticlesResponse) => void;
  };
}

type ArticleState = "PUBLISHED" | "DRAFT" | "SCHEDULED";

export const useCreateArticle = ({ input, functions }: Props) => {
  const [t] = useTranslation();
  const auth = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  /**
   * Post the article hooks
   */
  const { post } = usePost<GetArticlesResponse>({
    url: `${process.env.REACT_APP_DOMAIN}/api/v1/internal/articles`,
    params: {
      publisher: {
        id: input.publisherId,
      },
      title: input.title,
      body: input.outputBody,
      slug: "",
      state: "published",
      summary: input.summary,
      company_info: input.companyInfo,
      media_contact: input.mediaContact,
      use_thumbnail_as_featured_image: input.useThumbnailAsFeaturedImage,
      search_words: input.searchKeywords,
    },
    headers: {
      Authorization: `Bearer ${auth.authInfo.accessToken}`,
      country: input.country,
    },
  });

  /**
   * Create the article
   */
  const createArticle = async (state: ArticleState) => {
    try {
      const categoriesReq = createBusinessCategories(
        input.businessCategories,
        input.selectedCategories
      );

      if (input.title.length === 0) {
        console.error(t("hooks.no_title"));
        enqueueSnackbar(t("hooks.set_title"), {
          variant: "error",
        });
        functions.handleClose();
        functions.handleBackdropClose();
        return;
      }

      if (categoriesReq.length === 0) {
        console.error(t("hooks.no_business_categories"));
        enqueueSnackbar(t("hooks.set_business_categories"), {
          variant: "error",
        });
        functions.handleClose();
        functions.handleBackdropClose();
        return;
      }

      if (!input.acceptedFiles[0] && state === "PUBLISHED") {
        enqueueSnackbar(t("hooks.set_thumbnail"), { variant: "error" });
        functions.handleClose();
        functions.handleBackdropClose();
        return;
      }

      if (input.acceptedFiles[0] && !isPngOrJpeg(input.acceptedFiles[0])) {
        console.error(t("hooks.upload_image_file"));
        enqueueSnackbar(t("hooks.upload_image_file"), { variant: "error" });
        functions.handleClose();
        functions.handleBackdropClose();
        return;
      }

      const data: PostImageByFileResponseSchemaV2 =
        (input.acceptedFiles[0] || state === "PUBLISHED") &&
        (await uploadImageByFile(input.acceptedFiles[0], () => {
          console.error(t("hooks.image_upload_failed"));
          enqueueSnackbar(t("hooks.image_upload_failed"), { variant: "error" });
          functions.handleClose();
          functions.handleBackdropClose();
        }));

      if (data?.url?.length === 0) {
        console.error("Image upload failed.");
        enqueueSnackbar("Image upload failed.", { variant: "error" });
        functions.handleClose();
        functions.handleScheduleClose();
        functions.handleBackdropClose();
        return;
      }

      const htmlBody = createHtmlBody(input.outputBody, input.summary);

      post({
        params: {
          publisher: {
            id: input.publisherId,
          },
          title: input.title,
          published_at: input.publishedAt.toUTC().toFormat("yyyy-MM-dd HH:mm"),
          body: input.outputBody,
          html_body: htmlBody,
          thubmnail_image_url: data ? data.url : undefined,
          slug: "",
          // publishedAtが現時刻よりも先の場合はステータスをscheduledにする
          state:
            state === "PUBLISHED"
              ? input.publishedAt > DateTime.local()
                ? "scheduled"
                : "published"
              : "draft",
          business_categories: categoriesReq,
          summary: input.summary,
          company_info: input.companyInfo,
          media_contact: input.mediaContact,
          use_thumbnail_as_featured_image: input.useThumbnailAsFeaturedImage,
          search_words: input.searchKeywords,
        },
        onSuccess: (response) => {
          if (state === "PUBLISHED") {
            enqueueSnackbar(t("hooks.successfully_published"), {
              variant: "success",
            });
            functions.handleComplete(
              response as unknown as GetArticlesResponse
            );
          } else {
            enqueueSnackbar(t("hooks.successfully_saved_as_draft"), {
              variant: "success",
            });
            navigate("/dashboard");
          }
        },
        onError: () => {
          enqueueSnackbar(t("hooks.create_failed"), { variant: "error" });
        },
        onStart: () => {
          functions.handleBackDropOpen();
        },
        onEnd: () => {
          functions.handleBackdropClose();
          functions.handleScheduleClose();
          functions.handleClose();
        },
      });
    } catch (e) {
      if (e.message !== "") {
        enqueueSnackbar(e.message, { variant: "error" });
      } else {
        enqueueSnackbar(t("hooks.create_failed"), { variant: "error" });
      }
    }
  };

  return { createArticle };
};
