/** @jsxImportSource @emotion/react */
import { OutputData } from "@editorjs/editorjs";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import { Autocomplete, Checkbox, css, TextField } from "@mui/material";
import { DateTime } from "luxon";
import React, { ChangeEvent, SyntheticEvent, VFC } from "react";
import { DropzoneInputProps, DropzoneRootProps } from "react-dropzone";
import { useTranslation } from "react-i18next";
import { BusinessCategory } from "src/entities/BusinessCategory";
import { CountryPath } from "src/entities/CountryPath";
import { PublisherResponse } from "src/entities/v2/PublisherInfoResponse";
import { Auth } from "src/hooks/useAuth";
import createBusinessCategories from "src/utils/createBusinessCategories";
import getCountryLanguage from "src/utils/getCountryLanguage";
import { getPressReleaseGuidelineLink } from "src/utils/getPressReleaseGuidelineLink";
import { tools } from "../../../libs/editor/tools";
import ArticleDesktopPreview from "../article/ArticleDesktopPreview";
import Button from "../common/Button";
import InputField from "../common/InputField";
import { LightTooltip } from "../common/LightTooltip";
import { TemplateModal } from "./TemplateModal";

interface Props {
  // common
  auth: Auth;
  country: CountryPath;
  publisherResponse?: PublisherResponse;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ReactEditorJS: any;

  // article information
  publishedAt: DateTime;
  title: string;
  summary: string;
  selectedCategories: string[];
  thumbnailAsDefault: boolean;
  companyInfo: string;
  mediaContact: string;
  outputBody: OutputData;
  businessCategories: BusinessCategory[];

  // Dropzone param
  acceptedFiles: File[];
  isDragAccept: boolean;
  getRootProps: <T extends DropzoneRootProps>(props?: T) => T;
  getInputProps: <T extends DropzoneInputProps>(props?: T) => T;

  // functions
  handlePublishedAtChange: (value: DateTime) => void;
  handleBusinessCategories: (
    _: SyntheticEvent<Element, Event>,
    values: string[]
  ) => void;
  handleTitleChange: (value: string) => void;
  handleSummaryChange: (value: string) => void;
  handleThumbnailAsDefault: (e: ChangeEvent<HTMLInputElement>) => void;
  handleOutputBody: (data: OutputData) => void;
  handleInitialize: (instance) => void;
  handleSave: () => Promise<void>;
  handleCompanyInfo: (value: string) => void;
  handleMediaContact: (value: string) => void;
  handleClickSaveOpen: () => void;
  goToConfirmStep: () => void;
}

const sanitizerConfig = {
  br: true,
  p: true,
  b: true,
};

const STEPPER_HEIGHT = 80;

const CreateArticleStep: VFC<Props> = (props: Props) => {
  /**
   * Common parameters
   */
  const [t] = useTranslation();

  const [open, setOpen] = React.useState(false);

  return (
    <div css={conentWrapper}>
      <div css={inputArea}>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <TemplateModal
            country={props.country}
            handleTitleChange={props.handleTitleChange}
            handleSummaryChange={props.handleSummaryChange}
            handleOutputBody={props.handleOutputBody}
            setOpen={setOpen}
          />
          <a
            href={getPressReleaseGuidelineLink(props.country)}
            className="h-8 flex justify-center items-center content-center px-4 py-2 rounded-md border-solid border-2 border-primary bg-white hover:border-button-hover hover:text-button-hover disabled:border-opacity-50 disabled:hover:border-primary disabled:text-primary disabled:hover:border-opacity-50 disabled:text-opacity-50 disabled:hover:text-opacity-50 text-primary"
            style={{
              border: "1px solid #0045FF",
              width: 144,
              fontSize: 14,
            }}
            target="_blank"
            rel="noopener noreferrer"
          >
            {t("create_press_release.guideline.title")}
            <OpenInNewIcon style={{ marginLeft: 8 }} />
          </a>
        </div>
        <div className="my-4 p-4 bg-gray-100 rounded-md">
          <WarningAmberIcon className="mr-2" />
          {`${t("create_press_release.content.caution")} ${getCountryLanguage(
            props.country
          )}`}
        </div>
        <div style={{ width: "100%", marginBottom: 16 }}>
          <div css={contentTitle}>
            *{t("create_press_release.content.business_categories")}
            <div css={subtitle}>
              {t("create_press_release.guideline.description")}
            </div>
          </div>
          {props.businessCategories ? (
            <div style={{ marginBottom: 8 }}>
              <Autocomplete
                multiple
                value={props.selectedCategories}
                options={props.businessCategories.map((option) => option.name)}
                onChange={props.handleBusinessCategories}
                placeholder={t(
                  "create_press_release.content.category.placeholder"
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    placeholder={t(
                      "create_press_release.content.business_categories"
                    )}
                  />
                )}
              />
            </div>
          ) : null}
        </div>
        <div style={{ width: "100%", marginBottom: 16 }}>
          <div css={contentTitle}>
            *{t("create_press_release.content.title")}
          </div>
          <InputField
            value={props.title}
            type="text"
            onChange={props.handleTitleChange}
            placeholder={t("create_press_release.content.title_placeholder")}
          />
        </div>
        <div style={{ width: "100%", marginBottom: 16 }}>
          <div css={contentTitle}>
            {t("create_press_release.content.summary")}
          </div>
          <InputField
            value={props.summary}
            multiline
            type="text"
            onChange={props.handleSummaryChange}
            placeholder={t("create_press_release.content.summary_placeholder")}
          />
        </div>
        <div style={{ marginBottom: 16 }}>
          <div css={contentTitle} style={{ alignItems: "center" }}>
            {t("create_press_release.content.thumbnail")}
            <div
              style={{
                marginLeft: 16,
                display: "flex",
                alignItems: "center",
              }}
            >
              <Checkbox
                style={{ padding: 0 }}
                checked={props.thumbnailAsDefault}
                size="small"
                onChange={props.handleThumbnailAsDefault}
                inputProps={{ "aria-label": "controlled" }}
              />
              <span
                style={{
                  color: "#rgba(45, 45, 45, 0.8)",
                  fontSize: 14,
                  marginLeft: 4,
                }}
              >
                {t("create_press_release.content.thumbnail.use_at_top")}
              </span>
            </div>
          </div>
          <div style={{ display: "flex" }}>
            <div
              css={thumbnailStyle}
              style={{
                borderColor: props.isDragAccept && "#00e676",
              }}
              {...props.getRootProps()}
            >
              <input {...props.getInputProps()} />
              <p>{t("create_press_release.content.thumbnail_placeholder_1")}</p>
              <p>{t("create_press_release.content.thumbnail_placeholder_2")}</p>
            </div>
            <div>
              {props.acceptedFiles.length > 0 ? (
                props.acceptedFiles.map((file) => {
                  return (
                    <div
                      className="border ml-4 rounded-lg object-cover flex-shrink-0 bg-white"
                      style={{ height: 120, width: 160, overflow: "hidden" }}
                    >
                      <img
                        className="object-cover rounded-lg"
                        key={file.name}
                        src={URL.createObjectURL(file)}
                        alt="preview"
                        style={{ height: 120, width: 160 }}
                      />
                    </div>
                  );
                })
              ) : (
                <div
                  className="border rounded-lg"
                  style={{ height: 120, width: 160, marginLeft: 24 }}
                />
              )}
            </div>
          </div>
        </div>
        <div style={{ marginBottom: 16 }}>
          <div css={bodyTitleWrapper}>
            <div style={{ display: "flex", alignItems: "center" }}>
              *{t("create_press_release.content.body")}
              <div css={subtitle}>
                {t("create_press_release.content.body.placeholder")}
              </div>
            </div>
          </div>
          {!open && (
            <props.ReactEditorJS
              holder="custom"
              data={props.outputBody}
              onInitialize={props.handleInitialize}
              tools={tools}
              sanitizer={sanitizerConfig}
              onChange={props.handleSave}
            >
              <div id="custom" css={detailBody} />
            </props.ReactEditorJS>
          )}
        </div>
        <div style={{ width: "100%", marginBottom: 16 }}>
          <div css={contentTitle}>
            {t("create_press_release.content.company_info")}
          </div>
          <InputField
            value={props.companyInfo}
            multiline
            type="text"
            onChange={props.handleCompanyInfo}
            placeholder={t(
              "create_press_release.content.company_info_placeholder"
            )}
          />
        </div>
        <div style={{ width: "100%", marginBottom: 16 }}>
          <div css={contentTitle}>
            {t("create_press_release.content.media_contact")}
          </div>
          <InputField
            value={props.mediaContact}
            multiline
            type="text"
            onChange={props.handleMediaContact}
            placeholder={t(
              "create_press_release.content.media_contact_placeholder"
            )}
          />
        </div>
      </div>
      <div className="w-50% relative" css={previewArea}>
        <ArticleDesktopPreview
          title={props.title}
          publisherName={props.publisherResponse?.publisher?.name}
          publishedAt={props.publishedAt}
          summary={props.summary}
          thumbnail={
            props.thumbnailAsDefault ? props.acceptedFiles[0] : undefined
          }
          body={props.outputBody}
          companyInfo={props.companyInfo}
          mediaContact={props.mediaContact}
          stepperHeight={STEPPER_HEIGHT}
          businessCategories={createBusinessCategories(
            props.businessCategories,
            props.selectedCategories
          )}
        />
        <div className="flex justify-end items-center" css={buttonFooter}>
          <Button
            text={t("create_press_release.modal.button.save_as_draft")}
            onClick={() => {
              props.handleSave();
              props.handleClickSaveOpen();
            }}
            className="mr-4"
          />
          {props.title.length === 0 ||
          createBusinessCategories(
            props.businessCategories,
            props.selectedCategories
          ).length === 0 ||
          props.acceptedFiles.length === 0 ? (
            <LightTooltip
              placement="top"
              title={t(
                "create_press_release.content.business_categories.helper"
              )}
            >
              <span>
                <Button
                  text={t("general.next")}
                  disabled={
                    props.title.length === 0 ||
                    createBusinessCategories(
                      props.businessCategories,
                      props.selectedCategories
                    ).length === 0 ||
                    props.acceptedFiles.length === 0
                  }
                  onClick={() => {
                    props.handleSave();
                    props.goToConfirmStep();
                  }}
                  className="mr-4"
                />
              </span>
            </LightTooltip>
          ) : (
            <Button
              text={t("general.next")}
              disabled={
                props.title.length === 0 ||
                createBusinessCategories(
                  props.businessCategories,
                  props.selectedCategories
                ).length === 0 ||
                props.acceptedFiles.length === 0 ||
                props.outputBody === undefined ||
                props.outputBody?.blocks.length === 0
              }
              onClick={() => {
                props.handleSave();
                props.goToConfirmStep();
              }}
              className="mr-4"
            />
          )}
        </div>
      </div>
    </div>
  );
};

const conentWrapper = css`
  background: #ffffff;
  display: flex;
  height: calc(100vh - 80px - 4rem);
`;

const inputArea = css`
  padding: 32px;
  width: 50%;
  overflow: scroll;
  height: calc(100vh - 80px - 4rem);
`;

const detailBody = css`
  min-height: 200px;
  width: 100%;
  border: 1px solid #a0a3bd66;
  border-radius: 8px;
  border: h1 {
    font-size: xx-large;
    font-weight: bold;
  }
  h2 {
    font-size: x-large;
    font-weight: bold;
    padding-top: 16px;
    padding-bottom: 4px;
  }
  h3 {
    font-size: large;
    font-weight: bold;
    padding-top: 8px;
    padding-bottom: 2px;
  }
  h4 {
    font-size: medium;
    font-weight: bold;
  }
  .ce-block__content {
    margin-left: 64px;
    max-width: 100%;
  }
  .ce-toolbar__content {
    margin-left: 64px;
    max-width: 100%;
  }
  .codex-editor__redactor {
    padding-bottom: 0px !important;
  }
`;

const thumbnailStyle = css`
  flex: 1;
  max-width: 50%;
  height: 120px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 8px;
  border-color: #eeeeee;
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border 0.24s ease-in-out;
  cursor: pointer;
  :hover {
    color: #808080;
    border-color: #808080;
  }
`;

const subtitle = css`
  display: flex;
  color: #808080;
  font-size: 14px;
  align-items: center;
  margin-left: 12px;
`;

const contentTitle = css`
  display: flex;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  color: rgba(45, 45, 45, 0.8);
  margin-bottom: 8px;
`;

const bodyTitleWrapper = css`
  display: flex;
  align-items: center;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  color: rgba(45, 45, 45, 0.8);
  margin-bottom: 8px;
  justify-content: space-between;
`;

// const contentDescription = css`
//   display: flex;
//   color: #808080;
//   font-size: 14px;
//   align-items: center;
//   margin-left: 12px;
//   white-space: pre;
// `;

const previewArea = css`
  background: #eaeaea;
  display: flex;
  justify-content: center;
`;

const buttonFooter = css`
  position: absolute;
  height: 64px;
  width: 100%;
  background: #eaeaea;
  bottom: 0;
`;

export default CreateArticleStep;
