import { OutputData } from "@editorjs/editorjs";
import { DateTime } from "luxon";
import { useSnackbar } from "notistack";
import { MutableRefObject } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { BusinessCategory } from "src/entities/BusinessCategory";
import { CountryPath } from "src/entities/CountryPath";
import { GetArticlesResponse } from "src/entities/GetArticlesResponse";
import { GetArticleV2 } from "src/entities/v2/GetArticleV2";
import uploadImageByFile from "src/libs/image/post-image-by-image";
import { PostImageByFileResponseSchemaV2 } from "src/libs/types/image";
import createBusinessCategories from "src/utils/createBusinessCategories";
import { createHtmlBody } from "src/utils/editorjs/createHtmlBody";
import isPngOrJpeg from "src/utils/isPngOrJpeg";
import { usePut } from "./usePut";

interface Props {
  input: {
    editorCore: MutableRefObject<any>;
    article: GetArticleV2;
    publisherId: string;
    title: string;
    outputBody: OutputData;
    country: CountryPath;
    acceptedFiles: File[];
    publishedAt: DateTime;
    slug: string;
    businessCategories: BusinessCategory[];
    selectedCategories: string[];
    summary: string;
    companyInfo: string;
    mediaContact: string;
    useThumbnailAsFeaturedImage: boolean;
  };
  functions: {
    handleBackDropOpen: () => void;
    handleClose: () => void;
    handleScheduleClose: () => void;
    handleBackdropClose: () => void;
    handleSave: () => Promise<void>;
    handleComplete: (response: GetArticlesResponse) => void;
  };
}

type ArticleState = "PUBLISHED" | "DRAFT";

export const useUpdateArticle = ({ input, functions }: Props) => {
  const [t] = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  /**
   * Post the article hooks
   */
  const { put } = usePut({
    url: `${process.env.REACT_APP_DOMAIN}/api/v1/internal/articles/${input.slug}`,
    params: {
      publisher: {
        id: input.publisherId,
      },
      title: input.title,
      body: input.outputBody,
      slug: "",
      state: "published",
      summary: input.summary,
      company_info: input.companyInfo,
      media_contact: input.mediaContact,
      use_thumbnail_as_featured_image: input.useThumbnailAsFeaturedImage,
    },
    headers: {
      country: input.country,
    },
  });

  /**
   * Create the article
   */
  const updateArticle = async (state: ArticleState) => {
    if (input.title.length === 0) {
      console.error(t("hooks.no_title"));
      enqueueSnackbar(t("hooks.set_title"), {
        variant: "error",
      });
      functions.handleClose();
      functions.handleBackdropClose();
      return;
    }

    const categoriesReq = createBusinessCategories(
      input.businessCategories,
      input.selectedCategories
    );

    if (categoriesReq.length === 0) {
      console.error(t("hooks.no_business_categories"));
      enqueueSnackbar(t("hooks.set_business_categories"), {
        variant: "error",
      });
      functions.handleClose();
      functions.handleBackdropClose();
      return;
    }

    if (
      input.article.articles.thumbnail_image_url.length === 0 &&
      !input.acceptedFiles[0] &&
      state === "PUBLISHED"
    ) {
      enqueueSnackbar(t("hooks.set_thumbnail"), { variant: "error" });
      functions.handleClose();
      functions.handleBackdropClose();
      return;
    }

    if (input.acceptedFiles.length > 0) {
      if (!isPngOrJpeg(input.acceptedFiles[0])) {
        console.error(t("hooks.upload_image_file"));
        enqueueSnackbar(t("hooks.upload_image_file"), { variant: "error" });
        functions.handleClose();
        functions.handleBackdropClose();
        return;
      }

      const data: PostImageByFileResponseSchemaV2 = await uploadImageByFile(
        input.acceptedFiles[0]
      );

      if (data.url.length === 0) {
        console.error("Image upload failed.");
        enqueueSnackbar("Image upload failed.", { variant: "error" });
        functions.handleClose();
        functions.handleBackdropClose();
        return;
      }

      const htmlBody = createHtmlBody(input.outputBody, input.summary);

      put({
        params: {
          publisher: {
            id: input.publisherId,
          },
          title: input.title,
          published_at: input.publishedAt.toUTC().toFormat("yyyy-MM-dd HH:mm"),
          body: input.outputBody,
          html_body: htmlBody,
          thubmnail_image_url: data.url,
          slug: input.slug,
          state:
            state === "PUBLISHED"
              ? input.publishedAt > DateTime.local()
                ? "scheduled"
                : "published"
              : "draft",
          business_categories: categoriesReq,
          summary: input.summary,
          company_info: input.companyInfo,
          media_contact: input.mediaContact,
          use_thumbnail_as_featured_image: input.useThumbnailAsFeaturedImage,
        },
        onError: () => {
          enqueueSnackbar(t("hooks.edit_failed"), { variant: "error" });
        },
        onSuccess: (response) => {
          if (state === "PUBLISHED") {
            enqueueSnackbar(t("hooks.successfully_published"), {
              variant: "success",
            });
            if (
              input.article.articles.state === "draft" ||
              input.article.articles.state === "payment_failed"
            ) {
              functions.handleComplete(
                response as unknown as GetArticlesResponse
              );
            } else {
              navigate("/dashboard");
            }
          } else {
            enqueueSnackbar(t("hooks.successfully_saved_as_draft"), {
              variant: "success",
            });
            navigate("/dashboard");
          }
        },
        onStart: () => {
          functions.handleBackDropOpen();
          functions.handleSave();
        },
        onEnd: () => {
          functions.handleBackdropClose();
          functions.handleScheduleClose();
          functions.handleClose();
        },
      });
    } else {
      const htmlBody = createHtmlBody(input.outputBody, input.summary);

      put({
        params: {
          publisher: {
            id: input.publisherId,
          },
          title: input.title,
          published_at: input.publishedAt.toUTC().toFormat("yyyy-MM-dd HH:mm"),
          body: input.outputBody,
          html_body: htmlBody,
          thubmnail_image_url: input.article.articles.thumbnail_image_url,
          slug: input.slug,
          state:
            state === "PUBLISHED"
              ? input.publishedAt > DateTime.local()
                ? "scheduled"
                : "published"
              : "draft",
          business_categories: categoriesReq,
          summary: input.summary,
          company_info: input.companyInfo,
          media_contact: input.mediaContact,
          use_thumbnail_as_featured_image: input.useThumbnailAsFeaturedImage,
        },
        onError: () => {
          enqueueSnackbar(t("hooks.edit_failed"), { variant: "error" });
        },
        onSuccess: (response) => {
          if (state === "PUBLISHED") {
            enqueueSnackbar(t("hooks.successfully_published"), {
              variant: "success",
            });
            if (input.article.articles.state === "draft") {
              functions.handleComplete(
                response as unknown as GetArticlesResponse
              );
            } else {
              navigate("/dashboard");
            }
          } else {
            enqueueSnackbar(t("hooks.successfully_saved_as_draft"), {
              variant: "success",
            });
            navigate("/dashboard");
          }
        },
        onStart: () => {
          functions.handleBackDropOpen();
        },
        onEnd: () => {
          functions.handleBackdropClose();
          functions.handleScheduleClose();
          functions.handleClose();
        },
      });
    }
  };

  return { updateArticle };
};
