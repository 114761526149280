import CloseIcon from "@mui/icons-material/Close";
import EmailIcon from "@mui/icons-material/Email";
import MenuIcon from "@mui/icons-material/Menu";
import { CircularProgress, IconButton, Tooltip } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { useSnackbar } from "notistack";
import { Dispatch, SetStateAction, VFC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { AiOutlineWarning } from "react-icons/ai";
import { MdOutlineLogout, MdOutlineSettings } from "react-icons/md";
import {
  NavigateFunction,
  Outlet,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { CountryPath } from "src/entities/CountryPath";
import { AvailLanguage } from "src/utils/getBrowserLanguage";
import getCountryName from "src/utils/getCountryName";
import { LightTooltip } from "../components/common/LightTooltip";
import CancelEditingModal from "../components/modal/CancelEditingModal";
import ChangeCountryModal from "../components/modal/ChangeCountryModal";
import ChangePasswordModal from "../components/modal/ChangePasswordModal";
import SupportModal from "../components/modal/SupportModal";

interface Props {
  signout: (navigate: NavigateFunction) => boolean;
  lang: string;
  country: CountryPath;
  setCountry: Dispatch<SetStateAction<CountryPath>>;
  setLang: Dispatch<SetStateAction<AvailLanguage>>;
  credits?: string;
  isPublisherInfoLoading: boolean;
  is_subscripted: boolean;
  setPath?: Dispatch<SetStateAction<string>>;
}

const DashboardLayout: VFC<Props> = (props: Props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [t, i18n] = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = useState(false);
  const [changingCountry, setChangingCountry] = useState<CountryPath>();
  const regex = /(\/dashboard)/g;

  /**
   * Password reset dialog open
   */
  const [openPasswordReset, setOpenPasswordReset] = useState(false);
  /**
   * handle open password reset dialog
   */
  const handleClickOpenPasswordReset = () => {
    setOpenPasswordReset(true);
  };
  /**
   * handle close password reset dialog
   */
  const handleClosePasswordReset = () => {
    setOpenPasswordReset(false);
  };

  /**
   * Warning for no credits
   */
  const [openWarning, setOpenWarning] = useState(false);

  /**
   * Drawer parameter
   */
  const [drawerOpen, setDrawerOpen] = useState<boolean>(true);
  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };
  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  /**
   * support modal open param
   */
  const [openSupport, setOpenSupport] = useState(false);
  const handleOpenSupport = () => {
    setOpenSupport(true);
  };
  const handleCloseSupport = () => {
    setOpenSupport(false);
  };

  /**
   * Warning for singapore payment start
   */
  // const [openPaymentWarning, setOpenPaymentWarning] = useState(true);

  /**
   * Transition Open Parameter
   */
  const [openTransition, setOpenTransition] = useState(false);
  const [navigatePath, setNavigatePath] = useState("");
  const handleOpenTransition = () => {
    setOpenTransition(true);
  };
  const handleCloseTransition = () => {
    setOpenTransition(false);
  };

  useEffect(() => {
    if (props.lang) {
      i18n.changeLanguage(props.lang);
    } else {
      i18n.changeLanguage("en");
    }
  }, [i18n, props.lang]);

  useEffect(() => {
    if (props.credits !== undefined && parseInt(props.credits, 10) < 1) {
      setOpenWarning(true);
    } else {
      setOpenWarning(false);
    }
  }, [props.credits]);

  return (
    <div className="bg-background flex flex-col min-h-screen">
      <CancelEditingModal
        open={openTransition}
        handleClose={handleCloseTransition}
        onSubmit={() => {
          navigate(navigatePath);
          handleCloseTransition();
        }}
      />
      <ChangePasswordModal
        lang={props.lang}
        open={openPasswordReset}
        setLang={props.setLang}
        handleClose={handleClosePasswordReset}
      />
      <SupportModal open={openSupport} handleClose={handleCloseSupport} />
      <header style={{ zIndex: 1201 }} className="fixed flex-col w-full">
        <div className="w-full flex items-center justify-between px-4 h-16 py-2 bg-white border-b-2">
          <div className="flex items-center">
            <Tooltip
              title={
                drawerOpen ? t("sidemenu.menu.close") : t("sidemenu.menu.open")
              }
              placement="bottom"
            >
              <IconButton
                onClick={() => {
                  if (drawerOpen) {
                    handleDrawerClose();
                  } else {
                    handleDrawerOpen();
                  }
                }}
              >
                {drawerOpen ? <CloseIcon /> : <MenuIcon />}
              </IconButton>
            </Tooltip>
            <img className="h-8" src="/VRITTA_logo.png" alt="vritimes_logo" />
          </div>
          <div className="flex">
            <IconButton
              type="button"
              onClick={() => {
                handleOpenSupport();
              }}
              style={{
                marginRight: 8,
              }}
            >
              <EmailIcon />
            </IconButton>
            {props.is_subscripted ? (
              <LightTooltip
                title={t("sidemenu.credits_management.goto")}
                placement="left"
              >
                <button
                  type="button"
                  className="flex items-center border py-1 px-4 rounded-md hover:border-gray-400 hover:bg-gray-100"
                  onClick={() => {
                    if (location.pathname.includes("article")) {
                      setNavigatePath("/credits_management");
                      handleOpenTransition();
                    } else {
                      navigate("/credits_management");
                    }
                  }}
                  style={{
                    backgroundImage:
                      "linear-gradient(140deg, rgba(0, 45, 255, 1), rgba(4, 198, 239, 1))",
                  }}
                >
                  <div className="text-lg font-bold text-white">
                    {t("general.unlimited")}
                  </div>
                </button>
              </LightTooltip>
            ) : (
              <LightTooltip
                title={t("sidemenu.credits_management.goto")}
                placement="left"
              >
                <button
                  type="button"
                  className={
                    parseInt(props.credits, 10) < 1
                      ? "flex items-center border py-1 px-4 rounded-md bg-amber-100 hover:border-gray-400 hover:bg-amber-200"
                      : "flex items-center border py-1 px-4 rounded-md hover:border-gray-400 hover:bg-gray-100"
                  }
                  onClick={() => {
                    if (location.pathname.includes("article")) {
                      setNavigatePath("/credits_management");
                      handleOpenTransition();
                    } else {
                      navigate("/credits_management");
                    }
                  }}
                >
                  <div className="mr-2 text-md">
                    {getCountryName(props.country)} Credits
                  </div>
                  <div
                    className={
                      parseInt(props.credits, 10) < 1
                        ? "text-lg font-bold text-yellow-700"
                        : "text-lg font-bold text-primary"
                    }
                  >
                    {props.isPublisherInfoLoading ? (
                      <CircularProgress size={24} />
                    ) : (
                      <>
                        <span className="text-2xl">{props.credits}</span>{" "}
                        {t("payment.press_post.credit")}
                      </>
                    )}
                  </div>
                </button>
              </LightTooltip>
            )}
          </div>
        </div>
        {openWarning &&
        !props.is_subscripted &&
        !props.isPublisherInfoLoading ? (
          <div className="w-full border-b bg-amber-100 py-1 px-4 flex">
            <div className="mr-4 flex items-center text-yellow-700 font-bold">
              <AiOutlineWarning size="24px" className="mr-1" />
              {t("sidemenu.warn.idn.distribute")}
            </div>
            <button
              type="button"
              onClick={() => {
                if (location.pathname.includes("article")) {
                  setNavigatePath("/credits_management");
                  handleOpenTransition();
                } else {
                  navigate("/credits_management");
                }
              }}
              className="underline text-sky-600 hover:text-sky-800 font-bold"
            >
              {`>> ${t("sidemenu.credits_management.goto")}`}
            </button>
            <button
              type="button"
              className="ml-auto"
              onClick={() => {
                setOpenWarning(false);
              }}
            >
              <CloseIcon />
            </button>
          </div>
        ) : null}
      </header>
      <div
        style={{
          height: "calc(100vh - 4rem)",
        }}
        className="bg-white mt-16 w-full overflow-scroll flex"
      >
        {drawerOpen ? (
          <div
            className="bg-background h-full border-r-2"
            style={{
              height: "80hv",
              width: 272,
              minWidth: 272,
              overflowY: "scroll",
            }}
          >
            <ChangeCountryModal
              open={open}
              handleClose={() => {
                setOpen(false);
              }}
              country={changingCountry}
              changeCountry={() => {
                props.setCountry(changingCountry);
                setOpen(false);

                if (
                  location.pathname !== "/dashboard" &&
                  location.pathname !== "/article/new"
                ) {
                  navigate("/dashboard");
                }
              }}
            />
            <div className="flex items-center p-4 pb-2">
              <Select
                className="w-full"
                value={props.country}
                onChange={(e) => {
                  setChangingCountry(e.target.value as CountryPath);
                  setOpen(true);
                }}
                id="country_selector"
              >
                <MenuItem disabled={props.country === "IDN"} value="IDN">
                  Indonesia
                </MenuItem>
                <MenuItem disabled={props.country === "SGP"} value="SGP">
                  Singapore
                </MenuItem>
                <MenuItem disabled={props.country === "PHL"} value="PHL">
                  Philippines
                </MenuItem>
                <MenuItem disabled={props.country === "MYS"} value="MYS">
                  Malaysia
                </MenuItem>
                <MenuItem disabled={props.country === "THA"} value="THA">
                  Thailand
                </MenuItem>
                <MenuItem disabled={props.country === "VNM"} value="VNM">
                  Vietnam
                </MenuItem>
                <MenuItem disabled={props.country === "AUS"} value="AUS">
                  Australia
                </MenuItem>
              </Select>
            </div>
            <div className="flex flex-col justify-between">
              <div className="flex-col p-8 grid">
                <button
                  onClick={() => {
                    if (location.pathname.includes("article")) {
                      setNavigatePath("/dashboard");
                      handleOpenTransition();
                    } else {
                      navigate("/dashboard");
                    }
                  }}
                  type="button"
                  className={`flex rounded p-4 font-bold hover:bg-zinc-300 text-left ${
                    regex.test(location.pathname) && "text-primary"
                  }`}
                >
                  {/* <FiHome size="24px" className="mr-2" /> */}
                  {t("sidemenu.dashboard")}
                </button>
                <button
                  onClick={() => {
                    if (
                      location.pathname.includes("article") &&
                      location.pathname !== "/article/new"
                    ) {
                      setNavigatePath("/article/new");
                      handleOpenTransition();
                    } else {
                      navigate("/article/new");
                    }
                  }}
                  type="button"
                  className={`flex rounded p-4 w-full font-bold hover:bg-zinc-300 text-left ${
                    location.pathname === "/article/new" && "text-primary"
                  }`}
                >
                  {/* <FiHome size="24px" className="mr-2" /> */}
                  {t("sidemenu.press_release")}
                </button>
                <button
                  onClick={() => {
                    if (location.pathname.includes("article")) {
                      setNavigatePath("/analytics");
                      handleOpenTransition();
                    } else {
                      navigate("/analytics");
                    }
                  }}
                  type="button"
                  className={`flex rounded p-4 w-full font-bold hover:bg-zinc-300 text-left ${
                    location.pathname === "/analytics" && "text-primary"
                  }`}
                >
                  {/* <FiHome size="24px" className="mr-2" /> */}
                  {t("sidemenu.analytics")}
                </button>
                <button
                  onClick={() => {
                    if (location.pathname.includes("article")) {
                      setNavigatePath("/report");
                      handleOpenTransition();
                    } else {
                      navigate("/report");
                    }
                  }}
                  type="button"
                  className={`flex rounded p-4 w-full font-bold hover:bg-zinc-300 text-left ${
                    location.pathname === "/report" && "text-primary"
                  }`}
                >
                  {/* <FiHome size="24px" className="mr-2" /> */}
                  {t("sidemenu.report")}
                </button>
                <button
                  onClick={() => {
                    if (location.pathname.includes("article")) {
                      setNavigatePath("/settings");
                      handleOpenTransition();
                    } else {
                      navigate("/settings");
                    }
                  }}
                  type="button"
                  className={`flex rounded p-4 w-full font-bold hover:bg-zinc-300 text-left ${
                    location.pathname === "/settings" && "text-primary"
                  }`}
                >
                  {/* <FiHome size="24px" className="mr-2" /> */}
                  {t("sidemenu.settings")}
                </button>
                <button
                  onClick={() => {
                    if (location.pathname.includes("article")) {
                      setNavigatePath("/credits_management");
                      handleOpenTransition();
                    } else {
                      navigate("/credits_management");
                    }
                  }}
                  type="button"
                  className={`flex rounded p-4 w-full font-bold hover:bg-zinc-300 text-left ${
                    location.pathname === "/credits_management" &&
                    "text-primary"
                  }`}
                  id="sidemenu_billing"
                >
                  {/* <FiHome size="24px" className="mr-2" /> */}
                  {t("sidemenu.credits_management")}
                </button>
                <button
                  onClick={() => {
                    if (location.pathname.includes("article")) {
                      setNavigatePath("/additional_service");
                      handleOpenTransition();
                    } else {
                      navigate("/additional_service");
                    }
                  }}
                  type="button"
                  className={`flex rounded p-4 w-full font-bold hover:bg-zinc-300 text-left ${
                    location.pathname === "/additional_service" &&
                    "text-primary"
                  }`}
                >
                  {/* <FiHome size="24px" className="mr-2" /> */}
                  {t("sidemenu.additional_service")}
                </button>
              </div>
              <div className="flex-col p-8 grid">
                <button
                  onClick={() => {
                    handleClickOpenPasswordReset();
                  }}
                  className="flex rounded p-4 font-bold hover:bg-zinc-300 text-left items-center"
                  type="button"
                >
                  <MdOutlineSettings size="24px" className="mr-2" />
                  {t("modal.setting")}
                </button>
                <button
                  onClick={() => {
                    props.signout(navigate);
                    // eslint-disable-next-line no-unused-expressions
                    props.setPath && props.setPath("/dashboard");
                    enqueueSnackbar("Sign out succeeded.", {
                      variant: "success",
                    });
                  }}
                  className="flex rounded p-4 font-bold hover:bg-zinc-300 text-left items-center"
                  type="button"
                >
                  <MdOutlineLogout size="24px" className="mr-2" />
                  {t("sidemenu.sign_out")}
                </button>
              </div>
            </div>
          </div>
        ) : null}
        <Outlet />
      </div>
    </div>
  );
};

export default DashboardLayout;
