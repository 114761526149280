/** @jsxImportSource @emotion/react */
import { Divider } from "@mui/material";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import React, { VFC } from "react";
import { useTranslation } from "react-i18next";
import { CountryPath } from "src/entities/CountryPath";
import Button from "../components/common/Button";
import PageHeader from "../components/page/PageHeader";
import PageWrapper from "../components/page/PageWrapper";

interface Props {
  country: CountryPath;
}

const AdditionalServicePage: VFC<Props> = (props: Props) => {
  /**
   * Common parameters
   */
  const [t] = useTranslation();

  return (
    <LocalizationProvider dateAdapter={AdapterLuxon}>
      <PageWrapper>
        <PageHeader title={t("additional_service.page_header")} />
        <div className="w-75%">
          {/* Press Release */}
          <div className="mb-4">
            <div className="flex items-end justify-between mb-2">
              <div className="text-xl font-bold">
                {t("additional_service.press_release.title")}
              </div>
              <Button
                text="Request"
                variant="primary"
                openInNewTab
                onClick={() => {
                  window.open(
                    "https://docs.google.com/forms/d/e/1FAIpQLScuMZdpAKtDNAyJ2CpqlRqFdbNtacUxazjfnA9nE-vZp8Y-fQ/viewform",
                    "_blank"
                  );
                }}
              />
            </div>
            <Divider />
            <div className="flex items-start justify-between">
              <div className="py-4 px-8">
                <ul className="text-lg">
                  <li>{t("additional_service.press_release.writing")}</li>
                  <li>{t("additional_service.press_release.translation")}</li>
                  <li>{t("additional_service.press_release.proofreading")}</li>
                  <li>
                    {t("additional_service.press_release.additional_report")}
                  </li>
                </ul>
              </div>
              <div>
                <img
                  src="/additional_service/press_release.png"
                  alt="press release"
                  className="h-48"
                />
              </div>
            </div>
          </div>
          {/* Public Relations */}
          <div className="mb-4">
            <div className="flex items-end justify-between mb-2">
              <div className="text-xl font-bold">
                {t("additional_service.public_relations.title")}
              </div>
              <Button
                text="Request"
                variant="primary"
                openInNewTab
                onClick={() => {
                  window.open(
                    "https://docs.google.com/forms/d/e/1FAIpQLSftP2L6Xq9RZ0VbmPzyAmWGTEHmZLwJZgxx7C9y3pI5DbGxqw/viewform",
                    "_blank"
                  );
                }}
              />
            </div>
            <Divider />
            <div className="flex items-start justify-between">
              <div className="py-4 px-8">
                <ul className="text-lg">
                  <li>{t("additional_service.public_relations.top_media")}</li>
                  <li>{t("additional_service.public_relations.other_pr")}</li>
                </ul>
              </div>
              <div>
                <img
                  src="/additional_service/public_relation.png"
                  alt="press release"
                  className="h-48"
                />
              </div>
            </div>
          </div>
          {/* Find Services / Tools */}
          {props.country === "IDN" ? (
            <div>
              <div className="flex items-end justify-between mb-2">
                <div className="text-xl font-bold">
                  Find Services / Tools
                  {/* {t("additional_service.online_marketing.title")} */}
                </div>
                <Button
                  text="Detail"
                  variant="primary"
                  openInNewTab
                  onClick={() => {
                    window.open(
                      "https://www.vritimes.info/service-tools-id",
                      "_blank"
                    );
                  }}
                />
              </div>
              <Divider />
              <div className="flex items-start justify-between">
                <div className="py-4 px-8">
                  <ul className="text-lg">
                    <li>PR Agencies</li>
                    <li>Monitoring tools</li>
                    <li>Marketing Agencies</li>
                    {/* <li>{t("additional_service.online_marketing.SEO")}</li>
                    <li>{t("additional_service.online_marketing.SEM")}</li>
                    <li>{t("additional_service.online_marketing.SMM")}</li> */}
                  </ul>
                </div>
                <div>
                  <img
                    src="/additional_service/online_marketing.png"
                    alt="press release"
                    className="h-48"
                  />
                </div>
              </div>
            </div>
          ) : (
            // {/* Online Marketing */}
            <div>
              <div className="flex items-end justify-between mb-2">
                <div className="text-xl font-bold">
                  {t("additional_service.online_marketing.title")}
                </div>
                <Button
                  text="Request"
                  variant="primary"
                  openInNewTab
                  onClick={() => {
                    window.open(
                      "https://docs.google.com/forms/d/e/1FAIpQLSc-w_heqsPXhBVac6HK7LXuHhQPavNVdG9LCmp6lHWht6h8tA/viewform",
                      "_blank"
                    );
                  }}
                />
              </div>
              <Divider />
              <div className="flex items-start justify-between">
                <div className="py-4 px-8">
                  <ul className="text-lg">
                    <li>{t("additional_service.online_marketing.SEO")}</li>
                    <li>{t("additional_service.online_marketing.SEM")}</li>
                    <li>{t("additional_service.online_marketing.SMM")}</li>
                  </ul>
                </div>
                <div>
                  <img
                    src="/additional_service/online_marketing.png"
                    alt="press release"
                    className="h-48"
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </PageWrapper>
    </LocalizationProvider>
  );
};

export default AdditionalServicePage;
