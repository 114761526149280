/** @jsxImportSource @emotion/react */
import CloseIcon from "@mui/icons-material/Close";
import { Dialog, DialogActions, IconButton } from "@mui/material";
import { DateTimePicker } from "@mui/x-date-pickers";
import { t } from "i18next";
import { DateTime } from "luxon";
import { VFC } from "react";
import { Trans } from "react-i18next";
import { CountryPath } from "src/entities/CountryPath";
import getCountryName from "src/utils/getCountryName";
import { isAfterTenMinutes } from "src/utils/isAfterTenMinutes";
import Button from "../common/Button";

type ArticleState = "PUBLISHED" | "DRAFT";

interface Props {
  open: boolean;
  handleClose: () => void;
  createArticle: (state: ArticleState) => Promise<void>;
  handleBackDropOpen: () => void;
  publishedAt: DateTime;
  handlePublishedAtChange: (value: DateTime) => void;
  country: CountryPath;
}

const ScheduleArticleModal: VFC<Props> = (props: Props) => {
  return (
    <Dialog
      open={props.open}
      onClose={props.handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="sm"
      fullWidth
    >
      <div className="p-8">
        <div className="flex items-center justify-between">
          <div className="text-2xl font-bold">{t("general.schedule_post")}</div>
          <IconButton onClick={props.handleClose}>
            <CloseIcon />
          </IconButton>
        </div>
        <div className="text-base whitespace-pre-line text-black pt-4 pb-4">
          {t("create_press_release.modal.schedule.description")}
          <div style={{ width: "50%", marginTop: 16 }}>
            <div
              style={{
                color: "#rgba(45, 45, 45, 0.8)",
                fontSize: 14,
              }}
            >
              {t("create_press_release.content.scheduled_at")}
            </div>
            <DateTimePicker
              value={props.publishedAt}
              onChange={props.handlePublishedAtChange}
              minDateTime={DateTime.now()}
            />
          </div>
          {!isAfterTenMinutes(props.publishedAt) && (
            <div className="text-red-600">
              {t("general.error.arter_ten_minutes")}
            </div>
          )}
          <div className="pt-4">
            <Trans>
              {t("create_press_release.modal.post.schedule.description", {
                country: getCountryName(props.country),
              })}
            </Trans>
          </div>
        </div>
        <DialogActions>
          <Button
            text={t("create_press_release.modal.button.cancel")}
            onClick={props.handleClose}
          />
          <Button
            text={t("general.schedule_post")}
            disabled={!isAfterTenMinutes(props.publishedAt)}
            onClick={() => {
              props.handleBackDropOpen();
              props.createArticle("PUBLISHED");
            }}
          />
        </DialogActions>
      </div>
    </Dialog>
  );
};

export default ScheduleArticleModal;
