/** @jsxImportSource @emotion/react */
import InfoIcon from "@mui/icons-material/Info";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import {
  Backdrop,
  Button,
  css,
  Checkbox,
  Fade,
  IconButton,
  Paper,
  Popper,
  TextField,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { format, isAfter, subDays, subMonths } from "date-fns";
import PopupState, { bindToggle, bindPopper } from "material-ui-popup-state";
import { useSnackbar } from "notistack";
import { useEffect, useState, VFC } from "react";
import { useTranslation } from "react-i18next";
import { NavigateFunction, useNavigate } from "react-router";
import { DateRangePicker } from "rsuite";
import { AnalyticsGraphResponse } from "src/entities/AnalyticsGraphResponse";
import {
  AnalyticsSummaryResponse,
  GraphPair,
} from "src/entities/AnalyticsSummaryResponse";
import { CountryPath } from "src/entities/CountryPath";
import { useFetch } from "src/hooks/useFetch";
import { NumberUtils } from "src/utils/NumberUtils";
import getCountryName from "src/utils/getCountryName";
import DoughtChart from "../components/charts/analytics/DoughtChart";
import LineChart from "../components/charts/analytics/LineChart";
import { StyledLightTooltip } from "../components/common/StyledTooltip";
import PageContentsWrapper from "../components/page/PageContentsWrapper";
import PageHeader from "../components/page/PageHeader";
import PageWrapper from "../components/page/PageWrapper";
import StyledTab from "../components/tab/StyledTab";
import StyledTabs from "../components/tab/StyledTabs";
import TabPanel from "../components/tab/TabPanel";
import StyledTable from "../components/table/StyledTable";
import StyledTableBody from "../components/table/StyledTableBody";
import StyledTableCell from "../components/table/StyledTableCell";
import StyledTableHeader from "../components/table/StyledTableHeader";
import StyledTableHeaderRow from "../components/table/StyledTableHeaderRow";
import StyledTableRow from "../components/table/StyledTableRow";

import "rsuite/dist/rsuite.min.css";

interface Props {
  signout: (navigate: NavigateFunction) => boolean;
  lang: string;
  country: CountryPath;
  publisherId: string;
}

export type DateSelectionPeriod = "period" | "date";
type GraphTypeSelection = "view" | "other";

const AnalyticsPage: VFC<Props> = (props: Props) => {
  const navigate = useNavigate();
  const [t, i18n] = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [backdropOpen, setBackdropOpen] = useState(false);
  const [selectedSlug, setSelectedSlug] = useState<string>("");

  const [dateSelectionPeriod, setDateSelectionPeriod] =
    useState<DateSelectionPeriod>("period");
  const [graphType, setGraphType] = useState<GraphTypeSelection>("view");
  const [selectedArticle, setSelectedArticle] = useState<string[]>([]);
  const [summaryPeriod, setSummaryPeriod] = useState<[number, number]>([1, 30]);
  const [summaryDates, setSummaryDates] = useState<[Date, Date]>([
    subDays(new Date(), 32),
    subDays(new Date(), 2),
  ]);
  // default selected only set once on page load.
  const [firstTimeLoading, setFirstTimeLoading] = useState<boolean>(true);

  const getformattedDates = (dates?: [Date, Date]) => {
    const returnDates = dates || summaryDates;
    return {
      startDate: format(returnDates[0], "yyyy-MM-dd"),
      endDate: format(returnDates[1], "yyyy-MM-dd"),
    };
  };

  const handleDateSelectionChange = (
    _: React.SyntheticEvent,
    newValue: DateSelectionPeriod
  ) => {
    setDateSelectionPeriod(newValue);
  };

  const handleGraphTypeChange = (
    _: React.SyntheticEvent,
    newValue: GraphTypeSelection
  ) => setGraphType(newValue);

  //   const handleBackDropOpen = () => {
  //     setBackdropOpen(true);
  //   };

  //   const handleBackdropClose = () => {
  //     setBackdropOpen(false);
  //   };

  const dateSelectionPresets: { label: string; value: [Date, Date] }[] = [
    {
      label: "1 Week",
      value: [subDays(new Date(), 9), subDays(new Date(), 2)],
    },
    {
      label: "2 Weeks",
      value: [subDays(new Date(), 16), subDays(new Date(), 2)],
    },
    {
      label: "3 Weeks",
      value: [subDays(new Date(), 23), subDays(new Date(), 2)],
    },
    {
      label: "3 Months",
      value: [subMonths(subDays(new Date(), 2), 3), subDays(new Date(), 2)],
    },
  ];

  const periodSelectionPresets: { label: string; value: [number, number] }[] = [
    { label: "1 Week", value: [1, 7] },
    { label: "2 Weeks", value: [1, 14] },
    { label: "4 Weeks", value: [1, 28] },
    { label: "3 Months", value: [1, 90] },
  ];

  useEffect(() => {
    if (props.lang) {
      i18n.changeLanguage(props.lang);
    } else {
      i18n.changeLanguage("en");
    }
  }, [i18n, props.lang]);

  useEffect(() => {
    if (props.publisherId !== undefined) {
      refetchSummary();
    }
  }, [props.country]);

  const refetchAll = (options?: {
    period?: [number, number];
    date?: [Date, Date];
  }) => {
    const { period, date } = options || {};
    refetchSummary({
      params: {
        ...(dateSelectionPeriod === "period"
          ? {
              startPeriod: period ? period[0] : summaryPeriod[0],
              endPeriod: period ? period[1] : summaryPeriod[1],
            }
          : getformattedDates(date)),
      },
    });
    refetchGraphInfo({
      params: {
        ...(dateSelectionPeriod === "period"
          ? {
              startPeriod: period ? period[0] : summaryPeriod[0],
              endPeriod: period ? period[1] : summaryPeriod[1],
            }
          : getformattedDates(date)),
      },
      body: {
        article_ids: selectedArticle,
      },
    });
  };

  useEffect(() => {
    if (props.publisherId !== undefined) {
      refetchAll();
    }
  }, [dateSelectionPeriod, summaryDates]);

  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const {
    data: summaryInfo,
    isLoading: isSummaryInfo,
    refetch: refetchSummary,
  } = useFetch<AnalyticsSummaryResponse>({
    url: `${process.env.REACT_APP_DOMAIN}/api/v1/internal/publishers/${props.publisherId}/analytics/summary`,
    method: "post",
    params: {
      ...(dateSelectionPeriod === "period"
        ? { startPeriod: summaryPeriod[0], endPeriod: summaryPeriod[1] }
        : getformattedDates()),
    },
    skip: !props.publisherId,
    onSuccess: (data) => {
      if (firstTimeLoading) {
        setSelectedArticle(
          // eslint-disable-next-line @typescript-eslint/no-unsafe-return
          data.articles_summary_data.slice(0, 2).map((da) => da.article_id)
        );
        setFirstTimeLoading(false);
      }
    },
    onError: () => {
      enqueueSnackbar(t("error.login_again"), {
        variant: "error",
      });
      props.signout(navigate);
    },
  });

  const {
    data: graphInfo,
    // isLoading: isGraphInfo,
    refetch: refetchGraphInfo,
  } = useFetch<AnalyticsGraphResponse>({
    skip: selectedArticle.length === 0 || !props.publisherId,
    url: `${process.env.REACT_APP_DOMAIN}/api/v1/internal/publishers/${props.publisherId}/analytics/daily`,
    method: "post",
    params: {
      ...(dateSelectionPeriod === "period"
        ? { startPeriod: summaryPeriod[0], endPeriod: summaryPeriod[1] }
        : getformattedDates()),
    },
    body: {
      article_ids: selectedArticle,
    },
    onError: () => {
      enqueueSnackbar(t("error.login_again"), {
        variant: "error",
      });
      props.signout(navigate);
    },
  });

  const handleSelectionChanged = (id: string) => (_: any, checked: boolean) => {
    let newSelected = [...selectedArticle];
    if (checked) {
      newSelected.push(id);
    } else {
      newSelected = newSelected.filter((sel) => sel !== id);
    }
    setSelectedArticle(newSelected);
    refetchGraphInfo({
      params: {
        ...(dateSelectionPeriod === "period"
          ? { startPeriod: summaryPeriod[0], endPeriod: summaryPeriod[1] }
          : getformattedDates()),
      },
      body: {
        article_ids: newSelected,
      },
    });
  };

  const isDataReady = () =>
    summaryInfo && summaryInfo.articles_summary_data?.length > 0;

  return (
    <PageWrapper>
      <Backdrop sx={{ color: "#fff", zIndex: 10001 }} open={backdropOpen}>
        <CircularProgress color="inherit" />
      </Backdrop>

      <PageHeader title={t("analytics.page_header")} />
      <PageContentsWrapper>
        <div className="flex flex-col gap-1">
          <div className="flex gap-10">
            {/* <div className="text-md font-bold py-2">
              {t("analytics.summary.title")}
            </div> */}
            {isDataReady() ? (
              <SummaryDisplay
                title={t("analytics.summary.page_view")}
                number={summaryInfo?.total_data.total_page_views}
              />
            ) : null}

            {/* <SummaryDisplay
                title={t("analytics.summary.like")}
                number={fakeSummary.total_likes}
              />
              <SummaryDisplay
                title={t("analytics.summary.publication")}
                number={fakeSummary.total_publications}
              />
              <SummaryDisplay
                title={t("analytics.summary.ave")}
                number={fakeSummary.total_ave}
              /> */}
          </div>

          {isDataReady() && (
            <div className="flex flex-col gap-1">
              <div className="flex justify-end ">
                <StyledTabs
                  value={dateSelectionPeriod}
                  onChange={handleDateSelectionChange}
                  aria-label="Summary Type Selection"
                >
                  <StyledTab
                    value="period"
                    label={t("analytics.graph.period")}
                    {...a11yProps(0)}
                  />
                  <StyledTab
                    value="date"
                    label={t("analytics.graph.date")}
                    {...a11yProps(1)}
                  />
                </StyledTabs>
              </div>
              <div className="flex justify-end">
                <TabPanel value={dateSelectionPeriod} index="date">
                  <DateRangePicker
                    value={summaryDates}
                    onChange={setSummaryDates}
                    character=" - "
                    placement="bottomEnd"
                    ranges={dateSelectionPresets}
                    cleanable={false}
                    shouldDisableDate={(date) =>
                      isAfter(date, subDays(new Date(), 1))
                    }
                    format="MMM dd yyyy"
                  />
                </TabPanel>
                <TabPanel value={dateSelectionPeriod} index="period">
                  <PopupState variant="popper" popupId="popper-day-selection">
                    {(popupState) => (
                      <>
                        <Button
                          variant="outlined"
                          size="large"
                          style={{
                            color: "black",
                            borderColor: "#d5d5d5",
                            textTransform: "none",
                            fontWeight: 700,
                          }}
                          endIcon={<KeyboardArrowDownIcon />}
                          {...bindToggle(popupState)}
                        >
                          {`Day ${summaryPeriod[0]} - Day ${summaryPeriod[1]}`}
                        </Button>
                        <Popper
                          placement="bottom-end"
                          {...bindPopper(popupState)}
                          transition
                        >
                          {({ TransitionProps }) => (
                            <Fade {...TransitionProps} timeout={350}>
                              <Paper sx={{ padding: "4px", width: 400 }}>
                                <div className="flex justify-between p-2 m-2 border-b">
                                  <div className="flex gap-1 items-center">
                                    <div>Day</div>
                                    <TextField
                                      label=""
                                      value={summaryPeriod[0]}
                                      onChange={(event) =>
                                        setSummaryPeriod([
                                          parseInt(
                                            event.target.value || "0",
                                            10
                                          ),
                                          summaryPeriod[1],
                                        ])
                                      }
                                      type="number"
                                      size="small"
                                      style={{
                                        width: 64,
                                      }}
                                    />
                                    <div> - </div>
                                    <div>Day</div>
                                    <TextField
                                      label=""
                                      value={summaryPeriod[1]}
                                      onChange={(event) => {
                                        setSummaryPeriod([
                                          summaryPeriod[0],
                                          parseInt(
                                            event.target.value || "0",
                                            10
                                          ),
                                        ]);
                                      }}
                                      type="number"
                                      size="small"
                                      style={{
                                        width: 64,
                                      }}
                                    />
                                  </div>
                                  <Button
                                    size="small"
                                    variant="contained"
                                    onClick={() => {
                                      refetchAll();
                                      popupState.close();
                                    }}
                                    sx={{
                                      backgroundColor: "#0045FF",
                                    }}
                                  >
                                    {t("analytics.update.button")}
                                  </Button>
                                </div>
                                <div className="w-[300px] flex gap-1 items-center justify-between p-2 mx-2">
                                  {periodSelectionPresets.map(
                                    (preset, index) => (
                                      <button
                                        // eslint-disable-next-line react/no-array-index-key
                                        key={`period-selection-btn-${index}`}
                                        type="button"
                                        className="text-primary hover:underline hover:font-semibold"
                                        onClick={() => {
                                          setSummaryPeriod(preset.value);
                                          refetchAll({ period: preset.value });
                                          popupState.close();
                                        }}
                                      >
                                        {preset.label}
                                      </button>
                                    )
                                  )}
                                </div>
                              </Paper>
                            </Fade>
                          )}
                        </Popper>
                      </>
                    )}
                  </PopupState>
                </TabPanel>
              </div>
            </div>
          )}
        </div>
        {!props.publisherId || isSummaryInfo ? (
          <LoadingProgress />
        ) : isDataReady() ? (
          <div className="flex flex-col gap-1 mt-[-32px]">
            <div className="flex items-center">
              <StyledLightTooltip
                title={t("analytics.styled.light.tooltip1")}
                placement="left"
                arrow
              >
                <IconButton>
                  <InfoIcon sx={{ color: "rgba(0,0,0, 0.25)" }} />
                </IconButton>
              </StyledLightTooltip>
              <StyledTabs
                value={graphType}
                onChange={handleGraphTypeChange}
                aria-label="Graph Type Selection"
              >
                <StyledTab
                  value="view"
                  label={t("analytics.tabs.view")}
                  {...a11yProps(0)}
                />
                <StyledTab
                  value="other"
                  label={t("analytics.tabs.other")}
                  {...a11yProps(1)}
                />
              </StyledTabs>
            </div>
            <div className="flex justify-center">
              <TabPanel
                value={graphType}
                index="view"
                className="w-[100%] flex flex-col gap-2"
              >
                {graphInfo && (
                  <div id="analyitics-line-graph" className="flex h-[350px]">
                    <LineChart
                      summaryData={graphInfo}
                      selectionType={dateSelectionPeriod}
                    />
                  </div>
                )}
                <ViewTable
                  data={summaryInfo}
                  selected={selectedArticle}
                  onSelectChange={handleSelectionChanged}
                  setSlug={setSelectedSlug}
                />
              </TabPanel>
              <TabPanel
                value={graphType}
                index="other"
                className="w-[100%] flex flex-col gap-2"
              >
                <div className="flex gap-1 items-center justify-center h-[350px]">
                  <div className="w-[30%] h-full">
                    <DoughtChart
                      data={summaryInfo?.total_data.total_country_rate}
                      left="24%"
                      title={t("analytics.table.view.header.country")}
                    />
                  </div>
                  <div className="w-[30%] h-full">
                    <DoughtChart
                      data={summaryInfo?.total_data.total_city_rate}
                      left="28%"
                      title={t("analytics.table.view.header.city")}
                    />
                  </div>
                  <div className="w-[30%] h-full">
                    <DoughtChart
                      data={summaryInfo?.total_data.total_device_rate}
                      left="25%"
                      title={t("analytics.table.view.header.device")}
                    />
                  </div>
                </div>
                <OtherTable data={summaryInfo} setSlug={setSelectedSlug} />
              </TabPanel>
            </div>
          </div>
        ) : (
          <div>
            <div className="mb-2 font-semibold text-lg flex flex-col justify-center items-center">
              <img
                src="/analytics/no-data-analytics.png"
                alt="no_data"
                className="w-30%"
              />
              {/* eslint-disable-next-line react/jsx-curly-brace-presence */}
              {t("analytics.table.nodata.msg")}
            </div>
          </div>
        )}
      </PageContentsWrapper>
    </PageWrapper>
  );
};

interface ViewTableProps {
  data: AnalyticsSummaryResponse;
  selected: string[];
  onSelectChange: (id: string) => (_: any, checked: boolean) => void;
  setSlug: (id: string) => void;
}

const ViewTable: VFC<ViewTableProps> = ({
  data,
  selected,
  onSelectChange,
  setSlug,
}: ViewTableProps) => {
  const [t] = useTranslation();
  return (
    <StyledTable
      className="overflow-auto  max-h-[420px]"
      tableClassName="relative"
    >
      <StyledTableHeader>
        <StyledTableHeaderRow>
          <th
            className="sticky top-0 z-[5] px-4 w-5% border-r bg-gray-100"
            align="center"
          >
            {" "}
          </th>
          <th
            className="sticky top-0 z-[5] px-4 w-15% border-r bg-gray-100"
            align="left"
          >
            {t("analytics.table.view.header.title")}
          </th>
          <th
            className="sticky top-0 z-[5] px-4 w-10% border-r bg-gray-100"
            align="left"
          >
            {t("analytics.table.view.header.country")}
          </th>
          <th
            className="sticky top-0 z-[5] px-4 w-10% border-r bg-gray-100"
            align="left"
          >
            {t("analytics.table.view.header.date")}
          </th>
          <th
            className="sticky top-0 z-[5] px-4 w-10% border-r bg-gray-100"
            align="right"
          >
            {t("analytics.table.view.header.page_view")}
          </th>
          {/* <th
          className="sticky top-0 z-[5] px-4 w-10% border-r bg-gray-100"
          align="right"
        >
          {t("analytics.table.view.header.like")}
        </th>
        <th
          className="sticky top-0 z-[5] px-4 w-10% border-r bg-gray-100"
          align="right"
        >
          {t("analytics.table.view.header.pub")}
        </th>
        <th
          className="sticky top-0 z-[5] px-4 w-10% border-r bg-gray-100"
          align="right"
        >
          {t("analytics.table.view.header.ave")}
        </th> */}
          <th
            className="sticky top-0 z-[5] px-4 w-10% border-r bg-gray-100"
            align="right"
          >
            <StyledLightTooltip
              title={t("analytics.styled.light.tooltip2")}
              placement="left"
              arrow
            >
              <IconButton>
                <InfoIcon sx={{ color: "rgba(0,0,0, 0.25)" }} />
              </IconButton>
            </StyledLightTooltip>
            {t("analytics.table.view.header.rank")}
          </th>
          {/* <th
          className="sticky top-0 z-[5] px-4 w-10% border-r bg-gray-100"
          align="right"
        >
          {t("analytics.table.view.header.open_rate")}
        </th>
        <th
          className="sticky top-0 z-[5] px-4 w-10% border-r bg-gray-100"
          align="right"
        >
          {t("analytics.table.view.header.click_rate")}
        </th> */}
        </StyledTableHeaderRow>
      </StyledTableHeader>
      <StyledTableBody>
        {data.articles_summary_data?.map((article) => {
          return (
            <StyledTableRow key={article.article_id}>
              <StyledTableCell button>
                <div className="flex items-center gap-1">
                  {/* <IconButton
                    size="small"
                    onClick={() => {
                      setSlug(article.article_id);
                      //   handleMenuClick(e);
                    }}
                  >
                    <MoreVertIcon />
                  </IconButton> */}
                  <Checkbox
                    size="small"
                    checked={selected.includes(article.article_id)}
                    onChange={onSelectChange(article.article_id)}
                  />
                </div>
              </StyledTableCell>
              <StyledTableCell>
                <p className="truncate">{article.title}</p>
              </StyledTableCell>
              <StyledTableCell>
                <p>{getCountryName(article.country as CountryPath)}</p>
              </StyledTableCell>
              <StyledTableCell>
                <p>{article.published_at}</p>
              </StyledTableCell>
              {isWithinTwoDays(article.published_at) &&
              article.page_views === 0 ? (
                <StyledTableCell>
                  <StyledLightTooltip
                    title={t("analytics.styled.light.tooltip1")}
                    placement="left"
                    arrow
                  >
                    <p style={{ textAlign: "right" }}>Processing</p>
                  </StyledLightTooltip>
                </StyledTableCell>
              ) : (
                <StyledTableCell>
                  <p style={{ textAlign: "right" }}>
                    {NumberUtils.numberToFinancialDisplay(article.page_views)}
                  </p>
                </StyledTableCell>
              )}
              {/* <StyledTableCell>
              <p style={{ textAlign: "right" }}>{NumberUtils.numberToFinancialDisplay(article.likes)}</p>
            </StyledTableCell>
            <StyledTableCell>
              <p style={{ textAlign: "right" }}>{NumberUtils.numberToFinancialDisplay(article.publication)}</p>
            </StyledTableCell>
            <StyledTableCell>
              <p style={{ textAlign: "right" }}>{NumberUtils.numberToFinancialDisplay(article.ave)}</p>
            </StyledTableCell> */}
              {isWithinTwoDays(article.published_at) &&
              article.page_views === 0 ? (
                <StyledTableCell>
                  <StyledLightTooltip
                    title={t("analytics.styled.light.tooltip1")}
                    placement="left"
                    arrow
                  >
                    <p style={{ textAlign: "right" }}>Processing</p>
                  </StyledLightTooltip>
                </StyledTableCell>
              ) : (
                <StyledTableCell>
                  <p style={{ textAlign: "right" }}>
                    {NumberUtils.numberToFinancialDisplay(article.highest_rank)}
                  </p>
                </StyledTableCell>
              )}
              {/* <StyledTableCell>
              <p style={{ textAlign: "right" }}>
                {NumberUtils.percentageToDisplay(article.open_rate)}
              </p>
            </StyledTableCell>
            <StyledTableCell>
              <p style={{ textAlign: "right" }}>
                {NumberUtils.percentageToDisplay(article.click_rate)}
              </p>
            </StyledTableCell> */}
            </StyledTableRow>
          );
        })}
      </StyledTableBody>
    </StyledTable>
  );
};

interface OtherTableProps {
  data: AnalyticsSummaryResponse;
  setSlug: (id: string) => void;
}

const OtherTable: VFC<OtherTableProps> = ({
  data,
  setSlug,
}: OtherTableProps) => {
  const [t] = useTranslation();
  return (
    <StyledTable
      className="overflow-auto  max-h-[420px]"
      tableClassName="relative"
    >
      <StyledTableHeader>
        <StyledTableHeaderRow>
          {/* <th
            className="sticky top-0 z-[5] px-4 w-5% border-r bg-gray-100"
            align="center"
          >
            {" "}
          </th> */}
          <th
            className="sticky top-0 z-[5] px-4 w-35% border-r bg-gray-100"
            align="center"
          >
            {t("analytics.table.other.header.title")}
          </th>
          <th
            className="sticky top-0 z-[5] px-4 w-20% border-r bg-gray-100"
            align="center"
          >
            {t("analytics.table.other.header.country")}
          </th>
          <th
            className="sticky top-0 z-[5] px-4 w-20% border-r bg-gray-100"
            align="center"
          >
            {t("analytics.table.other.header.city")}
          </th>
          <th
            className="sticky top-0 z-[5] px-4 w-20% border-r bg-gray-100"
            align="center"
          >
            {t("analytics.table.other.header.device")}
          </th>
        </StyledTableHeaderRow>
      </StyledTableHeader>
      <StyledTableBody>
        {data.articles_summary_data?.map((article) => {
          return (
            <StyledTableRow key={article.article_id}>
              {/* <StyledTableCell button>
                <div className="flex items-center gap-1">
                  <IconButton
                    size="small"
                    onClick={() => {
                      setSlug(article.article_id);
                      //   handleMenuClick(e);
                    }}
                  >
                    <MoreVertIcon />
                  </IconButton>
                </div>
              </StyledTableCell> */}
              <StyledTableCell>
                <p className="truncate">{article.title}</p>
              </StyledTableCell>

              {isWithinTwoDays(article.published_at) &&
              article.page_views === 0 ? (
                <StyledTableCell>
                  <StyledLightTooltip
                    title={t("analytics.styled.light.tooltip1")}
                    placement="left"
                    arrow
                  >
                    <p style={{ textAlign: "right" }}>Processing</p>
                  </StyledLightTooltip>
                </StyledTableCell>
              ) : (
                <StyledTableCell>
                  <PercentageDisplay data={article.country_rate} />
                </StyledTableCell>
              )}
              {isWithinTwoDays(article.published_at) &&
              article.page_views === 0 ? (
                <StyledTableCell>
                  <StyledLightTooltip
                    title={t("analytics.styled.light.tooltip1")}
                    placement="left"
                    arrow
                  >
                    <p style={{ textAlign: "right" }}>Processing</p>
                  </StyledLightTooltip>
                </StyledTableCell>
              ) : (
                <StyledTableCell>
                  <PercentageDisplay data={article.city_rate} />
                </StyledTableCell>
              )}
              {isWithinTwoDays(article.published_at) &&
              article.page_views === 0 ? (
                <StyledTableCell>
                  <StyledLightTooltip
                    title={t("analytics.styled.light.tooltip1")}
                    placement="left"
                    arrow
                  >
                    <p style={{ textAlign: "right" }}>Processing</p>
                  </StyledLightTooltip>
                </StyledTableCell>
              ) : (
                <StyledTableCell>
                  <PercentageDisplay data={article.device_rate} />
                </StyledTableCell>
              )}
            </StyledTableRow>
          );
        })}
      </StyledTableBody>
    </StyledTable>
  );
};

interface SummaryProps {
  title: string;
  number: number;
}

const SummaryDisplay: VFC<SummaryProps> = ({ title, number }: SummaryProps) => {
  return (
    <div className="flex flex-col gap-2 p-4" css={summaryBoxStyle}>
      <p className="text-md text-gray-800 font-medium">{title}</p>
      <p className="text-xl text-primary font-medium">
        {NumberUtils.numberToFinancialDisplay(number)}
      </p>
    </div>
  );
};

interface PercentageDisplayProps {
  data: GraphPair[];
}

const PercentageDisplay: VFC<PercentageDisplayProps> = ({
  data,
}: PercentageDisplayProps) => {
  const topThreeData = data.sort((a, b) => b.value - a.value).slice(0, 3);
  return (
    <div className="flex flex-col gap-1 items-start">
      {topThreeData.map((displayData) => (
        <p className="text-md" key={`table-display-${displayData.label}`}>{`${
          displayData.label
        }: ${NumberUtils.percentageToDisplay(displayData.value)}`}</p>
      ))}
    </div>
  );
};

const LoadingProgress = () => (
  <div className="flex h-full w-full justify-center">
    <CircularProgress />
  </div>
);

/**
 * 日付が2日以内かどうかを判定する関数
 *
 * @param date 日付(yyyy-MM-ddのstring)
 */
const isWithinTwoDays = (date: string) => {
  const today = new Date();
  const targetDate = new Date(date);
  const diff = today.getTime() - targetDate.getTime();
  const diffDays = diff / (1000 * 60 * 60 * 24);
  return diffDays <= 2;
};

const addDescriptionStyle = css`
  flex: 1;
  width: 100%;
  height: 240px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 8px;
  border-color: #eeeeee;
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border 0.24s ease-in-out;
  cursor: pointer;
  font-size: 18px;
  :hover {
    color: #808080;
    border-color: #808080;
  }
`;

const summaryBoxStyle = css`
  width: 160px;
  height: 90px;
  background: #f5f5f5;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 15px;
`;

export default AnalyticsPage;
