/** @jsxImportSource @emotion/react */
import Cancel from "@mui/icons-material/Cancel";
import CheckCircle from "@mui/icons-material/CheckCircle";
import {
  Chip,
  css,
  Divider,
  FormControl,
  Grid,
  InputAdornment,
  TextField,
} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { useSnackbar } from "notistack";
import React, { useState, VFC } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { GetArticlesResponse } from "src/entities/GetArticlesResponse";
import { SearchKeyword } from "src/entities/SearchKeyword";
import { useAuth } from "src/hooks/useAuth";
import { useFetch } from "src/hooks/useFetch";
import { usePost } from "src/hooks/usePost";
import { usePut } from "src/hooks/usePut";
import tw from "twin.macro";
import Button from "../common/Button";
import StyledAutocomplete from "../common/StyledAutoComplete";

interface Props {
  title: string;
  publisherId: string;
  acceptedFiles: File[];
  searchKeywords: SearchKeyword[];
  setSearchKeywords: (keywords: SearchKeyword[]) => void;
  apiResponse?: GetArticlesResponse;
}

/**
 * ---今現在は使用していないコンポーネント---
 */
const DistributionSettingStep: VFC<Props> = (props: Props) => {
  const auth = useAuth();
  const navigate = useNavigate();
  const [t] = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  /**
   * Common parameters
   */
  const [selectedOption, setSelectedOption] = useState("New Product Launch");

  const distributionExamples = [
    {
      example: "New Product Launch",
      goodExample: ["VRITIMES", "NEW SERVICE NAME"],
      badExampleNotUse: ["CEO", "Japan"],
      badExampleTooCommon: ["launched", "product"],
    },
  ];

  const selectedDistributionExample = distributionExamples.find(
    (example) => example.example === selectedOption
  );

  /**
   * Get search word
   */
  const { data: getSearchKeywords } = useFetch<SearchKeyword[]>({
    url: `${process.env.REACT_APP_DOMAIN}/api/v1/internal/publishers/${props.publisherId}/searchWords`,
  });

  /**
   * Post the search word
   */
  const { post } = usePost<SearchKeyword>({
    url: `${process.env.REACT_APP_DOMAIN}/api/v1/internal/publishers/${props.publisherId}/searchWords`,
    headers: {
      Authorization: `Bearer ${auth.authInfo.accessToken}`,
    },
  });

  /**
   * Put the article hooks
   */
  const { put } = usePut({
    url: `${process.env.REACT_APP_DOMAIN}/api/v1/internal/articles/${props.apiResponse.data.slug}/searchWords`,
  });

  const handleEnterKeyDown = (event, params) => {
    if (
      event.key === "Enter" &&
      params.inputProps.value.trim() !== "" &&
      props.searchKeywords.length < 2
    ) {
      post({
        params: { name: params.inputProps.value },
        onSuccess: (response: any) => {
          props.setSearchKeywords([
            ...props.searchKeywords,
            {
              id: response.id,
              name: params.inputProps.value,
            },
          ]);
          props.searchKeywords.push({
            id: response.id,
            name: params.inputProps.value,
          });
        },
        onError: (e) => {
          console.log(e);
          enqueueSnackbar(
            t("create_press_release.distribution.keyword.failed"),
            { variant: "error" }
          );
        },
      });
    }
  };

  const selectedOnChange = (reason, details) => {
    if (reason !== "selectOption" || props.searchKeywords.length < 2) {
      if (reason === "selectOption") {
        props.setSearchKeywords([
          ...props.searchKeywords,
          {
            id: details.option.id,
            name: details.option.label,
          },
        ]);
        props.searchKeywords.push({
          id: details.option.id,
          name: details.option.label,
        });
      }
      if (reason === "removeOption") {
        props.setSearchKeywords(
          props.searchKeywords.filter((item) => item.name !== details.option)
        );
      }
    }
  };

  const completeButtonClick = () => {
    put({
      params: { search_words: props.searchKeywords },
      onSuccess: () => {
        enqueueSnackbar(
          t("create_press_release.distribution.keyword.success"),
          {
            variant: "success",
          }
        );
        navigate("/dashboard");
      },
      onError: (e) => {
        console.log(e);
        enqueueSnackbar(t("create_press_release.distribution.keyword.failed"), {
          variant: "error",
        });
      },
    });
  };

  return (
    <div css={conentWrapper}>
      <div
        css={css`
          ${tw`py-8 px-12`}
        `}
      >
        <Grid container spacing={0}>
          <Grid item xs={12}>
            <div
              css={css`
                ${tw`flex justify-between mb-2`}
              `}
            >
              <div
                css={css`
                  ${tw`text-2xl font-bold`}
                `}
              >
                {t("create_press_release.distribution.header")}
              </div>
              <button
                onClick={() => {
                  navigate("/dashboard");
                }}
                css={backButton}
                type="button"
              >
                <div css={goBackText}>
                  {t("create_press_release.complete.return.text")}
                </div>
                <img
                  width={25}
                  src="/articleEditor/arrow.png"
                  alt="arrow"
                  style={{ transform: "scaleX(-1)" }}
                />
              </button>
            </div>
            <div
              css={css`
                ${tw`text-base mb-4 whitespace-pre-wrap`}
              `}
            >
              {t("create_press_release.distribution.description")}
            </div>
            <div
              css={css`
                ${tw`mb-4`}
              `}
            >
              <div
                css={css`
                  ${tw`text-lg font-bold mb-1`}
                `}
              >
                {t("create_press_release.distribution.press.title")}
              </div>
              <div
                css={css`
                  ${tw`flex items-center`}
                `}
              >
                <div
                  css={css`
                    ${tw`border rounded-md`}
                  `}
                >
                  <img
                    src={URL.createObjectURL(props.acceptedFiles[0])}
                    alt="preview"
                    css={css`
                      ${tw`rounded-md object-cover`}
                      width: 80px;
                      height: 60px;
                    `}
                  />
                </div>
                <div
                  css={css`
                    ${tw`text-lg font-bold ml-4`}
                  `}
                >
                  {props.title}
                </div>
              </div>
            </div>
            <div>
              <div
                css={css`
                  ${tw`text-lg font-bold flex mb-1 items-center`}
                `}
              >
                {t("create_press_release.distribution.keyword.title")}
                <div
                  css={css`
                    ${tw`font-normal text-sm ml-2`}
                  `}
                >
                  {t("create_press_release.distribution.keyword.description")}
                </div>
              </div>
              <div
                css={css`
                  ${tw`flex items-center`}
                `}
              >
                {getSearchKeywords ? (
                  <StyledAutocomplete
                    multiple
                    style={{ width: "45%" }}
                    value={props.searchKeywords.map((option) => option.name)}
                    options={getSearchKeywords.map((option) => ({
                      label: option.name,
                      id: option.id,
                    }))}
                    onChange={(e, value, reason, details) => {
                      selectedOnChange(reason, details);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        placeholder={
                          props.searchKeywords.length !== 2
                            ? t(
                                "create_press_release.distribution.keywords.placeholder_new"
                              )
                            : t(
                                "create_press_release.distribution.keywords.placeholder_max"
                              )
                        }
                        onKeyDown={(e) => {
                          handleEnterKeyDown(e, params);
                        }}
                      />
                    )}
                  />
                ) : (
                  <StyledAutocomplete
                    multiple
                    style={{ width: "45%" }}
                    value={props.searchKeywords.map((option) => option.name)}
                    options={[]}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        placeholder={t(
                          "create_press_release.distribution.keyword.placeholder"
                        )}
                        onKeyDown={(e) => {
                          handleEnterKeyDown(e, params);
                        }}
                      />
                    )}
                  />
                )}
                <div
                  css={css`
                    ${tw`ml-4`}
                  `}
                >
                  <Button
                    text={t("general.complete")}
                    onClick={() => {
                      completeButtonClick();
                    }}
                    className="mr-4"
                  />
                </div>
              </div>
              <div>
                {t("create_press_release.distribution.keyword.sample.title")}
              </div>
            </div>
          </Grid>
          <Grid item xs={12}>
            <Divider
              css={css`
                ${tw`my-8`}
              `}
            />
          </Grid>
          <Grid
            item
            container
            xs={12}
            spacing={2}
            css={css`
              ${tw`overflow-x-scroll`}
            `}
          >
            <Grid item xs={5}>
              <div>
                <div>
                  <div
                    css={css`
                      ${tw`font-bold text-lg`}
                    `}
                  >
                    {t("create_press_release.distribution.how.title")}
                  </div>
                  <div css={subtitle}>
                    {t("create_press_release.distribution.how.subtitle1")}
                  </div>
                  <div css={subContent}>
                    {t("create_press_release.distribution.how.section1.line1")}
                  </div>
                  <div css={subContent}>
                    {t("create_press_release.distribution.how.section1.line2")}
                  </div>
                  <div css={subContent}>
                    {t("create_press_release.distribution.how.section1.line3")}
                  </div>
                  <div css={subtitle}>
                    {t("create_press_release.distribution.how.subtitle2")}
                  </div>
                  <div css={subContent}>
                    {t("create_press_release.distribution.how.section2.line1")}
                  </div>
                  <div css={subContent}>
                    {t("create_press_release.distribution.how.section2.line2")}
                  </div>
                  <div css={subtitle}>
                    {t("create_press_release.distribution.how.subtitle3")}
                  </div>
                  <div css={subContent}>
                    {t("create_press_release.distribution.how.section3.line1")}
                  </div>
                  <div css={subContent}>
                    {t("create_press_release.distribution.how.section3.line2")}
                  </div>
                  <div css={subContent}>
                    {t("create_press_release.distribution.how.section3.line3")}
                  </div>
                </div>
              </div>
            </Grid>
            <Grid item xs={7}>
              <div>
                <div css={cautionStyle}>
                  <div css={Examples}>
                    {t("create_press_release.distribution.example.title")}
                    <FormControl css={formControl}>
                      <Select
                        value={selectedOption}
                        style={{ height: "40px", color: "#808080" }}
                        onChange={(event) =>
                          setSelectedOption(event.target.value)
                        }
                      >
                        {distributionExamples.map((example) => (
                          <MenuItem
                            key={example.example}
                            value={example.example}
                          >
                            {example.example}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                  <div css={Examples} className="whitespace-pre-wrap">
                    {t(
                      "create_press_release.distribution.example.example.title"
                    )}
                  </div>
                  <div style={{ display: "flex" }}>
                    <CheckCircle css={checkCircle} />
                    <div
                      css={Examples}
                      style={{ color: "blue", paddingLeft: "5px" }}
                    >
                      {t("create_press_release.distribution.example.good")}
                    </div>
                  </div>
                  <div style={exampleStyles.root}>
                    <TextField
                      style={{ width: "100%" }}
                      InputProps={{
                        readOnly: true,
                        startAdornment: selectedDistributionExample
                          ? selectedDistributionExample.goodExample.map(
                              (goodExample) => (
                                <InputAdornment
                                  key={goodExample}
                                  position="start"
                                >
                                  <Chip
                                    label={goodExample}
                                    style={exampleStyles.chip}
                                  />
                                </InputAdornment>
                              )
                            )
                          : null,
                      }}
                    />
                  </div>
                  <div style={{ display: "flex" }}>
                    <Cancel css={cancelCircle} />
                    <div
                      css={Examples}
                      style={{ color: "red", paddingLeft: "5px" }}
                    >
                      {t("create_press_release.distribution.example.bad")}
                    </div>
                    <div css={Examples}>
                      {t(
                        "create_press_release.distribution.example.bad.description"
                      )}
                    </div>
                  </div>
                  <div style={exampleStyles.root}>
                    <TextField
                      style={{ width: "100%" }}
                      InputProps={{
                        readOnly: true,
                        startAdornment: selectedDistributionExample
                          ? selectedDistributionExample.badExampleNotUse.map(
                              (badExampleNotUse) => (
                                <InputAdornment
                                  key={badExampleNotUse}
                                  position="start"
                                >
                                  <Chip
                                    label={badExampleNotUse}
                                    style={exampleStyles.chip}
                                  />
                                </InputAdornment>
                              )
                            )
                          : null,
                      }}
                    />
                  </div>
                  <div style={{ display: "flex" }}>
                    <Cancel css={cancelCircle} />
                    <div
                      css={Examples}
                      style={{ color: "red", paddingLeft: "5px" }}
                    >
                      {t("create_press_release.distribution.example.bad")}
                    </div>
                    <div css={Examples}>
                      {t(
                        "create_press_release.distribution.example.bad.description2"
                      )}
                    </div>
                  </div>
                  <div style={exampleStyles.root}>
                    <TextField
                      style={{ width: "100%" }}
                      InputProps={{
                        readOnly: true,
                        startAdornment: selectedDistributionExample
                          ? selectedDistributionExample.badExampleTooCommon.map(
                              (badExampleTooCommon) => (
                                <InputAdornment
                                  key={badExampleTooCommon}
                                  position="start"
                                >
                                  <Chip
                                    key={badExampleTooCommon}
                                    label={badExampleTooCommon}
                                    style={exampleStyles.chip}
                                  />
                                </InputAdornment>
                              )
                            )
                          : null,
                      }}
                    />
                  </div>
                </div>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

const conentWrapper = css`
  background: #ffffff;
  display: flex;
`;

const backButton = css`
  display: flex;
  align-items: center;
  margin-right: 30px;
`;

const goBackText = css`
  margin-right: 8px;
  color: #0040ff;
`;

const Examples = css`
  display: flex;
  color: #808080;
  font-size: 16px;
  align-items: center;
`;

const subtitle = css`
  display: flex;
  color: #808080;
  font-size: 18px;
  font-weight: 600;
  align-items: center;
  padding-top: 8px;
  padding-left: 6px;
`;

const subContent = css`
  display: flex;
  color: #808080;
  font-size: 16px;
  align-items: center;
  padding-left: 24px;
`;

const checkCircle = css`
  color: blue;
  background-color: white;
  border-radius: 50%;
  font-size: 1.2rem;
`;

const cancelCircle = css`
  color: red;
  background-color: white;
  border-radius: 50%;
  font-size: 1.2rem;
`;

const formControl = css`
  margin-bottom: 8px;
  margin-left: 8em;
  width: -webkit-fill-available;
  font-size: 16px;
  background-color: #fff;
`;

const cautionStyle = css`
  margin: auto;
  margin-top: 20px;
  margin-left: 2px;
  max-width: 580px;
  min-width: 560px;
  padding: 32px;
  padding-top: 16px;
  padding-bottom: 16px;
  border-radius: 20px;
  border-color: #eeeeee;
  background-color: #eaeaea;
  color: #808080;
`;

const exampleStyles = {
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    marginBottom: "10px",
    background: "white",
  },
  chip: {
    marginRight: "8px",
    background: "#cbe4ff",
    color: "blue",
    fontSize: "15px",
  },
};

export default DistributionSettingStep;
