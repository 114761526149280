import { CountryPath } from "src/entities/CountryPath";

export const getPressReleaseGuidelineLink = (countryCode: CountryPath) => {
  if (countryCode === "IDN")
    return "https://drive.google.com/file/d/1wMZ3pt9T1tbhlXyDS36NBJFInxC0JbLK/view?usp=sharing";
  if (
    countryCode === "SGP" ||
    countryCode === "MYS" ||
    countryCode === "PHL" ||
    countryCode === "AUS"
  )
    return "https://drive.google.com/file/d/1aNEbHkc7X1Z03uxiTmUkEdUBH4R8aYQn/view?usp=sharing";
  if (countryCode === "THA")
    return "https://drive.google.com/file/d/1ZL2NcSdalZhG3438_6xAUdW5Jz_UtUBN/view?usp=sharing";
  if (countryCode === "VNM")
    return "https://drive.google.com/file/d/1s_nhxpDi7SLAql-PMCq5GB4G2POTbjy1/view?usp=sharing";
  return "https://drive.google.com/file/d/1aNEbHkc7X1Z03uxiTmUkEdUBH4R8aYQn/view?usp=sharing";
};
